import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const ResearchPageTemplate = ({title, content, image, imageCaption, contentComponent}) => {
  const PageContent = contentComponent || Content
  return (
    <div className="container">
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="section">
            <h1 className="title">{title}</h1>
            <hr />
            <div className="columns">
              {image && (
                <div className="column is-one-third">
                  <img src={image.childImageSharp.fluid.src} />
                  {imageCaption && <p className="is-size-7">{imageCaption}</p>}
                </div>
              )}
              <div className="column">
                <PageContent className="content" content={content} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ResearchPageTemplate.propTypes = {
}

const ResearchPage = ({ data }) => {
  const { markdownRemark } = data
  return (
    <Layout>
      <ResearchPageTemplate 
        title={markdownRemark.frontmatter.title} 
        contentComponent={HTMLContent}
        content={markdownRemark.html}
        image={markdownRemark.frontmatter.image}
        imageCaption={markdownRemark.frontmatter.imageCaption}
      />
    </Layout>
  )
}

ResearchPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ResearchPage

export const teachingPageQuery = graphql`
  query ResearchPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageCaption
      }
    }
  }
`
